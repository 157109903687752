<template>
  <PageSection class="charter-costs">
    <template #default>
      <div class="container">
        <div class="header">
          <h3
            class="title-small"
            v-inview-animate="{name: 'fade-down', duration: 500, delay: 300}"
          >{{content?.title}}</h3>
        </div>
        <CostTable
          :theme="true"
          :content="content"
        />
      </div>
    </template>
  </PageSection>
</template>

<script>
import CostTable from '../../../components/CostTable.vue';
import PageSection from '../../../components/PageSection.vue';

export default {
  components: { PageSection, CostTable },
  name: 'CharterCosts',
  props: {
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.charter-costs {
  @include wRule(
    padding,
    (
      xl: 120 0,
      lg: 80 0,
      sm: 84 0 80,
    )
  );

  border-bottom: 1px solid color(section-border);

  ::v-deep(.cost-table) {
    @include wRule(
      margin-top,
      (
        xl: 52,
        lg: 46,
        sm: 15,
      )
    );
  }
}
</style>
