<template>
  <PageSection
    class="screen centered order-charter"
    :bg="stepsBg"
    :theme="true"
  >
    <template #default>
      <div class="container">
        <div class="header">
          <h3
            class="title-small"
            v-inview-animate="{name: 'fade-down', duration: 500, delay: 400}"
          >{{content?.title_steps}}</h3>
        </div>
        <CharterSteps />
        <div
          class="links"
          v-inview-animate="{name: 'fade-down', duration: 500, delay: 800}"
        >
          <VButton
            :tag="'button'"
            type="button"
            @click="orderCharter"
          >{{content?.charter_order_button_text}}</VButton>
        </div>
      </div>
    </template>
  </PageSection>
</template>

<script>
import CharterSteps from '../../../components/CharterSteps.vue';
import PageSection from '../../../components/PageSection.vue';
import VButton from '../../../components/VButton.vue';

export default {
  components: { PageSection, CharterSteps, VButton },
  name: 'OrderCharter',
  emits: ['orderCharter'],
  props: {
    content: {
      type: Object,
    },
  },
  computed: {
    stepsBg() {
      return {
        background: this.content?.steps_background,
      };
    },
  },
  methods: {
    orderCharter() {
      this.$emit('orderCharter', {
        component: 'PopupOrderForm',
        isResult: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-charter {
  display: flex;

  &:not(:first-child) {
    @include wRule(
      margin-top,
      (
        xl: 110,
        lg: 80,
        sm: 70,
      )
    );
  }

  @include wRule(
    padding,
    (
      xl: 116 0 138,
      lg: 107 0 112,
      sm: 80 0 144,
    )
  );

  @include screen("sm", max) {
    min-height: 100vh;
    display: flex;
  }

  .header {
    @media screen and (min-width: 1940px) {
      margin-bottom: auto;
    }
  }

  ::v-deep(.container) {
    @include screen("sm", max) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  ::v-deep(.bg-media) {
    &::after {
      @include pseudo;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(26, 42, 68, 0.136) 0%,
        rgba(19, 29, 45, 0.76) 48.41%
      );
      opacity: 0.9;
      z-index: 2;
    }
  }

  .links {
    margin-top: auto;

    @include screen("sm", max) {
      width: 100%;
    }
  }

  ::v-deep(.v-button) {
    @include wRule(
      height,
      (
        xl: 84,
        lg: 52,
        sm: 88,
      )
    );

    @include wRule(
      min-width,
      (
        xl: 470,
        lg: 214,
        sm: 100%,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 24,
        lg: 16,
        sm: 28,
      )
    );
  }

  .charter-steps {
    align-self: flex-start;

    @media screen and (min-width: 1940px) {
      width: 100%;
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 120,
          lg: 76,
          sm: 62,
        )
      );

      @media screen and (min-width: 1940px) {
        margin-top: 60px;
      }
    }

    &:not(:last-child) {
      @include wRule(
        margin-bottom,
        (
          xl: 60,
          lg: 76,
          sm: 96,
        )
      );
    }
  }
}
</style>
