<template>
  <component
    :is="tag"
    class="info-block"
  >
    <BgMedia :source="bg" />
    <div class="content">
      <slot></slot>
    </div>
  </component>
</template>

<script>
import BgMedia from './BgMedia.vue';

export default {
  components: { BgMedia },
  name: 'InfoBlock',
  props: {
    tag: {
      type: String,
      default: 'article',
    },
    bg: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.info-block {
  $b: &;
  position: relative;
  display: block;
  width: 100%;
  color: color(basic-light);
  overflow: hidden;

  @include screen("sm", max) {
    color: color(basic);
  }

  ::v-deep(.bg-media) {
    width: 100%;
    transition: transform $ease-main;

    @include ratio(390, 380);

    @include screen("sm", max) {
      @include ratio(270, 270);
    }
    z-index: 1;
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    overflow: hidden;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.2);
    transition: background $ease-main;

    @include screen("sm", max) {
      position: relative;
      top: auto;
      left: auto;
      bottom: auto;
      right: auto;
      background-color: transparent;
    }

    @include wRule(
      padding,
      (
        xl: 40,
        lg: 24,
        sm: 30 0 0,
      )
    );
  }

  ::v-deep(.title) {
    line-height: 1.28;
    font-weight: 700;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.18);

    @include wRule(
      font-size,
      (
        xl: 32,
        lg: 24,
        sm: 28,
      )
    );

    @include screen("sm", max) {
      text-shadow: none;
    }
  }

  ::v-deep(.text) {
    display: block;
    width: 100%;
    transform: translate3d(0, 20px, 0);
    opacity: 0;
    transition: opacity $ease-main, transform $ease-main;
    line-height: 1.4;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 28,
      )
    );

    @include screen("sm", max) {
      display: none;
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 18,
          lg: 22,
          sm: 0,
        )
      );
    }

    &::before {
      display: block;
      content: "";
      background-color: color(border-blue);

      @include wRule(
        margin-bottom,
        (
          xl: 18,
          lg: 22,
          sm: 0,
        )
      );

      @include wRule(
        height,
        (
          xl: 4,
          lg: 2,
          sm: 0,
        )
      );

      @include wRule(
        width,
        (
          xl: 40,
          lg: 40,
          sm: 0,
        )
      );
    }
  }

  &:hover {
    .content {
      @include screen("sm", min) {
        background-color: rgba(0, 0, 0, 0.36);
      }
    }

    ::v-deep(.bg-media) {
      @include screen("sm", min) {
        transform: scale(1.1);
      }
    }

    ::v-deep(.text) {
      @include screen("sm", min) {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
</style>
