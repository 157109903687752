<template>
  <div class="page-container">
    <CharterTypes :content="pageContent" />
    <OrderCharter
      :content="pageContent"
      @order-charter="orderCharter"
    />
    <CharterCosts :content="costTable" />
    <CharterPresentation :content="pageContent" />
  </div>
</template>

<script>
import CharterCosts from './components/CharterCosts.vue';
import CharterPresentation from './components/CharterPresentation.vue';
import CharterTypes from './components/CharterTypes.vue';
import OrderCharter from './components/OrderCharter.vue';
import api from '../../tools/api';

export default {
  name: 'Charter',
  components: {
    CharterTypes,
    OrderCharter,
    CharterCosts,
    CharterPresentation,
  },
  emits: ['orderCharter'],
  data() {
    return {
      pageContent: {},
      costTable: {},
      contentLoading: true,
      costLoading: true,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    orderCharter(data) {
      this.$emit('orderCharter', data);
    },
    async getPageContent() {
      const results = await api('charter', true);
      this.contentLoading = !results.statusText === 'ok';
      this.pageContent = results.data;
    },
    async getCostTable() {
      const results = await api('price-table', true);
      this.costLoading = !results.statusText === 'ok';
      this.costTable = results.data;
    },
  },
  watch: {
    lang() {
      this.getPageContent();
      this.getCostTable();
    },
  },
  created() {
    this.getPageContent();
    this.getCostTable();
  },
};
</script>
