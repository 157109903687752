<template>
  <PageSection class="charter-types">
    <template #default>
      <Breadcrumbs />
      <div class="container">
        <div class="header">
          <h3
            class="title"
            v-if="content?.title"
            v-inview-animate="{name: 'fade-down', duration: 500, delay: 300}"
          >{{content?.title}}</h3>
          <p
            class="text-big"
            v-html="content?.description"
            v-if="content?.description"
            v-inview-animate="{name: 'fade-down', duration: 500, delay: 400}"
          ></p>
        </div>
        <div class="info-grid">
          <InfoBlock
            :bg="type?.image"
            v-for="(type, typeId) in content?.charter_category"
            :key="`type-${typeId}`"
            v-inview-animate="{name: 'fade-down', duration: 500, delay: (400 + typeId * 100)}"
          >
            <template #default>
              <div class="title">{{type?.title}}</div>
              <div
                class="text"
                v-html="type?.text"
              ></div>
            </template>
          </InfoBlock>
        </div>
      </div>
    </template>
  </PageSection>
</template>

<script>
import Breadcrumbs from '../../../components/Breadcrumbs.vue';
import PageSection from '../../../components/PageSection.vue';
import InfoBlock from '../../../components/InfoBlock.vue';

export default {
  components: { PageSection, Breadcrumbs, InfoBlock },
  name: 'CharterTypes',
  props: {
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.charter-types {
  min-height: 100vh;

  @include wRule(
    padding-top,
    (
      sm: 184,
    )
  );

  ::v-deep(.text-big) {
    @include wRule(
      max-width,
      (
        xl: 1020,
        lg: 790,
        sm: 100%,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 50,
          lg: 26,
          sm: 32,
        )
      );
    }
  }

  .info-grid {
    display: grid;
    width: 100%;

    @include wRule(
      column-gap,
      (
        xl: 58,
        lg: 32,
        sm: 18,
      )
    );
    @include wRule(
      row-gap,
      (
        xl: 70,
        lg: 32,
        sm: 40,
      )
    );

    @include rule(
      grid-template-columns,
      (
        xl: repeat(auto-fill, pxtovw(390, xl)),
        lg: repeat(auto-fill, pxtovw(276, lg)),
        sm: repeat(2, 1fr),
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 90,
          lg: 65,
          sm: 60,
        )
      );
    }
  }
}
</style>
