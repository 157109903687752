<template>
  <PageSection class="charter-presentation">
    <template #default>
      <div class="container">
        <div class="presentation">
          <div
            class="preview"
            v-inview-animate="{name: 'fade', duration: 500, delay: 400}"
            v-if="content?.presentation_picture"
          >
            <BgMedia :source="content?.presentation_picture" />
          </div>
          <div class="description">
            <h3
              class="title-small"
              v-inview-animate="{name: 'fade-down', duration: 500, delay: 300}"
            >{{content?.title_presentation}}</h3>
            <!-- <p
              class="text-small"
              v-html="content?.presentation_description"
              v-inview-animate="{name: 'fade-down', duration: 500, delay: 400}"
            ></p> -->
          </div>
          <div
            class="buttons"
            v-inview-animate="{name: 'fade-down', duration: 500, delay: 500}"
          >
            <VButton
              :tag="'a'"
              :href="getUrl(content?.presentation_button?.file?.url)"
              target="_blank"

              v-if="content?.presentation_button?.file?.url"
            >{{content?.presentation_button?.text}}</VButton>
          </div>
        </div>
      </div>
    </template>
  </PageSection>
</template>

<script>
import BgMedia from '../../../components/BgMedia.vue';
import PageSection from '../../../components/PageSection.vue';
import VButton from '../../../components/VButton.vue';

export default {
  components: { PageSection, VButton, BgMedia },
  name: 'CharterPresentation',
  props: {
    content: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.charter-presentation {
  @include wRule(
    padding,
    (
      xl: 140 0 152,
      lg: 80 0 86,
      sm: 105 0,
    )
  );

  ::v-deep(.container) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .presentation {
    display: inline-grid;
    align-items: center;

    @include wRule(
      grid-template-columns,
      (
        xl: 152 auto,
        lg: 116 auto,
        sm: 82 1fr,
      )
    );

    @include wRule(
      column-gap,
      (
        xl: 50,
        lg: 56,
        sm: 20,
      )
    );

    @media screen and (min-width: 1940px) {
      margin: 0 auto;
      grid-template-columns: 152px auto;
    }
  }

  .preview {
    display: block;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    box-sizing: border-box;

    @include screen("sm", max) {
      grid-row: 1 / 2;
    }

    .bg-media {
      @include ratio(223, 280);

      ::v-deep(picture img) {
        object-fit: contain;
      }
    }
  }

  .description {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    box-sizing: border-box;

    @include wRule(
      margin-top,
      (
        xl: -10,
        lg: -5,
        sm: -5,
      )
    );

    @media screen and (min-width: 1940px) {
      margin-top: 0;
      align-self: end;
      margin-bottom: 28px;
    }

    ::v-deep(.title-small) {
      @include wRule(
        max-width,
        (
          sm: 270,
        )
      );
    }

    ::v-deep(.text-small) {
      @include rule(
        line-height,
        (
          xl: 1.6,
          lg: 1.5,
          sm: 1.6,
        )
      );

      @include wRule(
        font-size,
        (
          sm: 24,
        )
      );
      @include wRule(
        max-width,
        (
          xl: 502,
          lg: 380,
          sm: 100%,
        )
      );

      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: 24,
            lg: 16,
            sm: 20,
          )
        );
      }
    }
  }

  .buttons {
    grid-column: 2 / 3;
    grid-row: 2 / 3;

    @include screen("sm", max) {
      grid-column: 1 / 3;
    }

    @media screen and (min-width: 1940px) {
      align-self: start;
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 20,
          lg: 20,
          sm: 88,
        )
      );

      @media screen and (min-width: 1940px) {
        margin-top: 28px;
      }
    }
  }

  ::v-deep(.v-button) {
    @include wRule(
      min-width,
      (
        xl: 257,
        lg: 227,
        sm: 100%,
      )
    );
  }
}
</style>
